import { render, staticRenderFns } from "./forms-download-reprt.vue?vue&type=template&id=b071fec0&scoped=true&"
import script from "./forms-download-reprt.vue?vue&type=script&lang=js&"
export * from "./forms-download-reprt.vue?vue&type=script&lang=js&"
import style0 from "./forms-download-reprt.vue?vue&type=style&index=0&id=b071fec0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b071fec0",
  null
  
)

export default component.exports