































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

interface ISourceParams {
    [key: string]: IGuSourceItem
}

interface IGuSourceItem {
    guCode: string,
    gu_name_ru: string,
    gu_name_kk: string,
    gu_name_en: string,
    prgs: {[key: string]: IPrgSourceItem},
    local_name?: string
}

interface IPrgSourceItem {
    prgCode: number,
    prg_name_ru: string,
    prg_name_kk: string,
    pprs: {[key: string]: IPprSourceItem} | null,
    local_name?: string
}

interface IPprSourceItem {
    pprCode: number | null,
    ppr_name_ru: string,
    ppr_name_kk: string,
    form_codes: Array<string>,
    local_name?: string
}

interface ITransferTableItem {
    isSelected: boolean,
    rowNumber: number,
    sourceFormCode: string,
    targetFormCode: string | null,
    isError: boolean
}

@Component({
    name: 'c-budget-forms-data-transfer',
})

export default class CBudgetFormsDataTransfer extends Vue {
    
    @Prop({
        required: true,
    })
    private header!: any;

    @Prop({
        required: true,
    })
    private lng!: string;

    @Prop({
        required: true,
    })
    private getDataTransferText!: any;

    @Prop({
        required: true,
    })
    private getErrText!: any;

    @Prop({
        required: true,
    })
    private makeToast!: any;

    // ----------- Получение крайних дат -------------------

    private sourceParams: ISourceParams | null = null;
    private targetForms: Array<string> | null = null;
    private isTransferLoading: boolean = false;
    private sourceYear: number | null = null;
    private sourceGu: IGuSourceItem | null = null;
    private sourcePrg: IPrgSourceItem | null = null;
    private sourcePpr: IPprSourceItem | null = null;
    private selectAll: boolean = false;
    private optionsModalMode: string | null = null;
    private areSelectedRowsExist: boolean = false;
    private formsToTransfer: Array<ITransferTableItem> = [];
    private formsWithTransferError: Array<string> = [];

    private get tableFields() {
        if (this.$i18n.locale) {
            return [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'rowNumber',
                    label: '№'
                },
                {
                    key: 'sourceFormCode',
                    label: ''
                },
                {
                    key: 'targetFormCode',
                    label: `${this.getDataTransferText('transfer_exist')}<br>${this.targetOptions}`
                },
            ];
            };
            return [];
    }
    
    private get sourceYearList(): Array<number> {
        const yearList = []
        if (this.header && this.header.cur_year) {
            for (let i = 0; i <= 2; i++) {
                yearList.push(this.header.cur_year + i);
            }
        }
        return yearList;
    }

    private get sourceGuList(): Array<IGuSourceItem> {
        const guList = [];
        if (this.sourceParams) {
            for (const guCode in this.sourceParams) {
                const guItem: IGuSourceItem = {
                    guCode: guCode,
                    gu_name_ru: this.sourceParams[guCode].gu_name_ru,
                    gu_name_kk: this.sourceParams[guCode].gu_name_kk,
                    gu_name_en: this.sourceParams[guCode].gu_name_en,
                    prgs: this.sourceParams[guCode].prgs
                }
                Object.defineProperty(guItem, 'local_name', {
                    get: () => {
                        const name = `${guItem.guCode} - `;
                        if (this.lng === 'kk') return name + guItem.gu_name_kk;
                        return name + guItem.gu_name_ru;
                    }
                });
                guList.push(guItem);
            }
        }
        return guList;
    }

    private get sourcePrgList(): Array<IPrgSourceItem> {
        const prgList = [];
        if (this.sourceGu && this.sourceGu.prgs) {
            const prgsDict = this.sourceGu.prgs;
            for (const prgCode in prgsDict) {
                const prgItem: IPrgSourceItem = {
                    prgCode: Number(prgCode),
                    prg_name_ru: prgsDict[prgCode].prg_name_ru,
                    prg_name_kk: prgsDict[prgCode].prg_name_kk,
                    pprs: prgsDict[prgCode].pprs,
                }
                Object.defineProperty(prgItem, 'local_name', {
                    get: () => {
                        const name = `${prgItem.prgCode} - `;
                        if (this.lng === 'kk') return name + prgItem.prg_name_kk;
                        return name + prgItem.prg_name_ru;
                    }
                });
                prgList.push(prgItem);
            }
        }
        return prgList;
    }

    private get sourcePprList(): Array<IPprSourceItem> {
        const pprList = [];
        if (this.sourcePrg && this.sourcePrg.pprs) {
            const pprsDict = this.sourcePrg.pprs;
            for (const pprCode in pprsDict) {
                const pprItem: IPprSourceItem = {
                    pprCode: pprCode === 'null' ? 0 : Number(pprCode),
                    ppr_name_ru: pprsDict[pprCode].ppr_name_ru ? pprsDict[pprCode].ppr_name_ru : 'Null',
                    ppr_name_kk: pprsDict[pprCode].ppr_name_kk ? pprsDict[pprCode].ppr_name_kk : 'Null',
                    form_codes: pprsDict[pprCode].form_codes,
                }
                Object.defineProperty(pprItem, 'local_name', {
                    get: () => {
                        const name = `${pprItem.pprCode} - `;
                        if (this.lng === 'kk') return name + pprItem.ppr_name_kk;
                        return name + pprItem.ppr_name_ru;
                    }
                });
                pprList.push(pprItem);
            }
        }
        return pprList;
    }

    private get transferTableData(): Array<ITransferTableItem> {
        const transferTable: Array<ITransferTableItem> = [];
        if (!this.targetForms || !this.sourcePpr || !this.sourcePpr.form_codes || !this.sourcePpr.form_codes.length) return transferTable;
        const formCodes = this.sourcePpr.form_codes;
        formCodes.forEach((item: string, idx) => {
            const targetFormCode = this.targetForms!.includes(item) ? item : null;
            const tableRow: ITransferTableItem = {
                isSelected: false,
                rowNumber: idx + 1,
                sourceFormCode: item,
                targetFormCode: targetFormCode,
                isError: this.formsWithTransferError.includes(item)
            }
            transferTable.push(tableRow);
        })
        return transferTable;
    }

    private get isMainModalBtnsDisabled(): Boolean {
        const areSourceParamsEqualTargetParams = (
            this.header &&
            this.sourceYear === this.header.year
            && this.sourceGu?.guCode === this.header.gu
            && this.sourcePrg?.prgCode === this.header.prg
            && this.sourcePpr?.pprCode === this.header.ppr
        )
        if (areSourceParamsEqualTargetParams) {
            this.makeToast('warning', this.getDataTransferText('transfer_imposible'), this.getDataTransferText('transfer_imposible_reason1'));
        }
        const areAllSourceParamsSelected = !!this.sourceYear && !!this.sourceGu && !!this.sourcePrg && !!this.sourcePpr
        return !areAllSourceParamsSelected || areSourceParamsEqualTargetParams;
    }

    private get sourceOptions() {
        if (!this.sourceYear || !this.sourceGu || !this.sourcePrg || !this.sourcePpr) return '';
        const options = `(${this.sourceYear}
                            -${this.sourceGu.guCode}
                            -${this.sourcePrg.prgCode}
                            -${this.sourcePpr.pprCode})`
        return options;
    }

    private get targetOptions() {
        if (!this.header) return '';
        const options = `(${this.header.year} 
                            - ${this.header.gu} 
                            - ${this.header.prg} 
                            - ${this.header.ppr})`
        return options;
    }

    private get localLableField(): string {
        return `lable_name_${this.lng}`;
    }

    private get areEmptyTargetFormsExist(): boolean {
        for (const row of this.transferTableData) {
            if (!row.targetFormCode) return true;
        }
        return false;
    }

    created() {
        this.addWatchers();
        
    }

    private addWatchers() {
        this.$watch('sourceGuList', this.setInitialSourceGu);
        this.$watch('sourceYear', this.getSourceParams);
        this.$watch('sourcePrgList', this.setInitialSourcePrg);
        this.$watch('sourcePprList', this.setInitialSourcePpr);
    }

    private setInitialSourceYear() {
        if (this.header) {
            this.sourceYear = this.header.year;
        }
    }

    private setInitialSourceGu() {
        if (this.sourceGuList && this.sourceGuList.length && this.header) {
            const filterGuItem = this.sourceGuList.find(item => item.guCode === this.header.gu);
            if (filterGuItem) {
                this.sourceGu = filterGuItem;
            } else {
                this.sourceGu = this.sourceGuList[0];
            }
        }
    }

    private setInitialSourcePrg() {
        this.sourcePrg = null;
    }

    private setInitialSourcePpr() {
        this.sourcePpr = null;
    }

    private onDataTransferClicked() {
        this.setInitialSourceYear();
    }

    private onTransferAndDelClicked() {
        this.optionsModalMode = 'move';
        this.$bvModal.show('data-transfer-options-modal')
        this.getTargetFormsWithData();
    }

    private onCopyClicked() {
        this.optionsModalMode = 'copy';
        this.$bvModal.show('data-transfer-options-modal');
        this.getTargetFormsWithData();
    }

    private onCancelClicked() {
        this.$bvModal.hide('data-transfer-main-modal');
        this.$bvModal.hide('data-transfer-options-modal');
    }

    private onSelectAllClicked(val: boolean) {
        this.transferTableData.forEach((item: any) => item.isSelected = val);
        this.areSelectedRowsExist = val;
    }

    private onSelectItem(val: boolean) {
        if (!val) this.selectAll = val;
        this.setAreSelectedRowsExist();
    }

    private onTransferOptionsBackClicked() {
        this.$bvModal.hide('data-transfer-options-modal');
    }

    private onReplaceEmptyClicked() {
        this.formsToTransfer.splice(0);
        this.transferTableData.forEach((item: any) => {
            if (!item.targetFormCode) {
                this.formsToTransfer.push(JSON.parse(JSON.stringify(item)));
            }
        })
        this.transferData();
    }

    private onReplaceAllClicked() {
        this.formsToTransfer.splice(0);
        let isTargetFormWithDataExist = false;
        this.transferTableData.forEach((item: any) => {
            if (item.targetFormCode) {
                isTargetFormWithDataExist = true;
            }
            this.formsToTransfer.push(JSON.parse(JSON.stringify(item)));
        })
        if (isTargetFormWithDataExist) {
            this.$bvModal.show('data-transfer-confirmation-modal');
        } else {
            this.transferData();
        }
    }

    private onReplaceSelectedClicked() {
        this.formsToTransfer.splice(0);
        let isTargetFormWithDataExist = false;
        this.transferTableData.forEach((item: any) => {
            if (item.isSelected) {
                this.formsToTransfer.push(JSON.parse(JSON.stringify(item)));
                if (item.targetFormCode) {
                    isTargetFormWithDataExist = true;
                }
            }
        })
        if (isTargetFormWithDataExist) {
            this.$bvModal.show('data-transfer-confirmation-modal');
        } else {
            this.transferData();
        }
    }

    private onReplaceBtnClicked() {
        this.$bvModal.hide('data-transfer-confirmation-modal');
        this.transferData();
    }


    private async getSourceParams() {
        if (!this.sourceYear) return;
        this.isTransferLoading = true;
        this.resetParams();
        const data = {
            curYear: this.header.cur_year,
            year: this.sourceYear,
            regionCode: this.header.region_code,
            variant: this.header.variant,
            userId: this.header.user_name
        }

        try {
            const response = await fetch('/api-py/get-params-for-data-transfer/',
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                });
            if (response.ok) {
                const result = await response.json();
                this.sourceParams = result;
            }
            else {
                this.makeToast('danger', `${this.getErrText('bad_request')} getSourceParams()`, `Error code: ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', `${this.getErrText('bad_request')} getSourceParams()`, error.toString());
        } finally {
            this.isTransferLoading = false;
        }
    }

    private async getTargetFormsWithData() {
        this.targetForms = null;
        if (!this.header || !this.sourcePpr || !this.sourcePpr.form_codes || !this.sourcePpr.form_codes.length) return;
        this.isTransferLoading = true;
        try {
            const response = await fetch('/api-py/get-target-forms-for-data-transfer/',
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({...this.header, source_form_codes: this.sourcePpr.form_codes})
                });
            if (response.ok) {
                const result = await response.json();
                this.targetForms = result;
            }
            else {
                this.makeToast('danger', `${this.getErrText('bad_request')} getTargetFormsWithData()`, `Error code: ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', `${this.getErrText('bad_request')} getTargetFormsWithData()`, error.toString());
        } finally {
            this.isTransferLoading = false;
        }
    }

    private async transferData() {
        if (!this.header || !this.formsToTransfer || !this.optionsModalMode || !this.sourceYear
            || !this.sourceGu || !this.sourcePrg || !this.sourcePpr) {
                this.makeToast('danger', `${this.getErrText('bad_request')} transferData()`, `Error code: data not provided`);
                return;
            }
        
        this.isTransferLoading = true;
        const data = {
            header: this.header,
            formsToTransfer: this.formsToTransfer,
            transferMode: this.optionsModalMode,
            sourceParams: {
                year: this.sourceYear,
                gu: this.sourceGu?.guCode,
                prg: this.sourcePrg?.prgCode,
                ppr: this.sourcePpr?.pprCode === 0 ? null : this.sourcePpr?.pprCode
            }
        }

        try {
            const response = await fetch('/api-py/budget-forms-transfer-data/',
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                });
            if (response.ok) {
                const result = await response.json();
                if (result.status === 'success') {
                    this.makeToast('success', this.getDataTransferText('transfer_success'), this.optionsModalMode === 'move' ? this.getDataTransferText('transfer_transfered') : this.getDataTransferText('transfer_copyed'));
                    this.onCancelClicked();
                    this.$emit('reloadTable');
                } 
                if (result.status === 'failure') {
                    this.makeToast('danger', `${this.getErrText('bad_request')} transferData()`, this.getDataTransferText('transfer_failure'));
                }
                if (result.status === 'partial_success') {
                    this.makeToast('warning', `${this.getErrText('bad_request')} transferData()`, this.getDataTransferText('transfer_partial_success'));
                    this.formsWithTransferError = result.forms_with_error;
                    this.getTargetFormsWithData();
                }
            }
            else {
                this.makeToast('danger', `${this.getErrText('bad_request')} transferData()`, `Error code: ${response.status}`);
            }
        } catch (error) {
            this.makeToast('danger', `${this.getErrText('bad_request')} transferData()`, error.toString());
        } finally {
            setTimeout(() => {this.isTransferLoading = false;}, 2000);
        }
    }

    private resetParams() {
        this.sourceParams = null;
        this.sourceGu = null;
        this.sourcePrg = null;
        this.targetForms = null;
    }

    private resetMainModal() {
        this.sourceYear = null;
        this.resetParams();
    }

    private resetOptionsModal() {
        this.optionsModalMode = null;
        this.selectAll = false;
        this.targetForms = null;
        this.areSelectedRowsExist = false;
        this.formsToTransfer.splice(0);
        this.formsWithTransferError.splice(0);
    }

    private getLocTitle(field: string, id: any = -1): any {
        const mainTextFieldName = "modules.budget_request.filter." + field;
        if (id >= 0) return this.$tc(mainTextFieldName, id);
        return this.$t(mainTextFieldName);
    }

    private setAreSelectedRowsExist() {
        const selectedItem = this.transferTableData.find((item: any) => item.isSelected == true);
        if (selectedItem) this.areSelectedRowsExist = true
        else this.areSelectedRowsExist = false;
    }

    private isFormWithError(formCode: string) {
        const isError = this.formsWithTransferError.length > 0 && this.formsWithTransferError.includes(formCode);
        return isError;
    }
}

