<template>
        <div class="actions-tab actions-tab-budget-forms">
             <!-- скачивание инструкций -->
            <budget-attach-file/>
            
            <b-dropdown 
                variant="link" 
                right toggle-class="text-decoration-none" 
                no-caret 
                menu-class="custom-dropdown-111"
                :disabled="isReportUploading || progress != 100" 
                ref="dropdown" 
                @shown="adjustDropdownMenuStyle"
            >
                <template #button-content>
                    <div class="btn-download">
                        <div>
                    <span class="left">
                        <b-spinner v-if="isReportUploading" variant="success" small label="Small Spinner"></b-spinner>
                        <i v-if="!isReportUploading" class="icon icon-download"></i> {{ buttonsText.download }}
                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item class="download-rep_dropdown-item"> 
                    <div class="pdf-link" @click="onClick('pdf', false)">
                        <img src="img/svg-icons/pdf.svg" alt="pdf icon"> 
                        {{ formLocalText }}
                    </div>
                    <div class="excel-link"  @click="onClick('xls', false)">
                        <img class="excel-icon" src="img/svg-icons/excel.svg" alt="excel icon">
                    </div>
                </b-dropdown-item>
                <b-dropdown-item class="download-rep_dropdown-item" v-if="isGkkpMode && isGkkpPaid">
                    <div class="pdf-link" @click="onClickPaid('pdf', false)">
                        <img src="img/svg-icons/pdf.svg" alt="pdf icon"> 
                        {{ formLocalPaid }}
                    </div>
                    <div class="excel-link" @click="onClickPaid('xls', false)">
                        <img class="excel-icon" src="img/svg-icons/excel.svg" alt="excel icon">
                    </div>
                </b-dropdown-item>
                <b-dropdown-item class="download-rep_dropdown-item" :disabled="$store.state.isDownloadButtonEnabled">
                    <div class="pdf-link" @click="onClick('pdf', true, true)">
                        <img src="img/svg-icons/pdf.svg" alt="pdf icon"> 
                        {{ batchReportLocalText }}
                    </div>
                    <div class="excel-link" @click="onClick('xls', true, true)">
                        <img class="excel-icon" src="img/svg-icons/excel.svg" alt="excel icon">
                    </div>
                </b-dropdown-item>
                <b-dropdown-item class="download-rep_dropdown-item" v-if="isGkkpMode" :disabled="$store.state.isDownloadButtonEnabled">
                    <div class="pdf-link" @click="onClickPaid('pdf', true, true)">
                        <img src="img/svg-icons/pdf.svg" alt="pdf icon"> 
                        {{ batchReportGkkpPaid }}
                    </div>
                    <div class="excel-link" @click="onClickPaid('xls', true, true)">
                        <img class="excel-icon" src="img/svg-icons/excel.svg" alt="excel icon">
                    </div>
                </b-dropdown-item>
                <b-dropdown-item 
                    class="download-rep_dropdown-item"
                    :disabled="$store.state.isDownloadButtonEnabled" 
                    v-if="isABPReportAvailable"
                >
                    <div class="pdf-link" @click="onClick('pdf', true, false)" >
                        <img src="img/svg-icons/pdf.svg" alt="pdf icon"> 
                        {{ batchReportAbpLocalText }}
                    </div>
                    <div class="excel-link" @click="onClick('xls', true, false)" >
                        <img class="excel-icon" src="img/svg-icons/excel.svg" alt="excel icon">
                    </div>
                </b-dropdown-item>
                <b-dropdown-item 
                    v-if="isParentGuExist" 
                    class="download-rep_dropdown-item"
                    :disabled="$store.state.isDownloadButtonEnabled"
                > 
                    <div class="pdf-link" @click="onClick('pdf', true, false, 'edu')">
                        <img src="img/svg-icons/pdf.svg" alt="pdf icon"> 
                        {{ batchReportEducationDepText }}
                    </div>
                    <div class="excel-link" @click="onClick('xls', true, false, 'edu')">
                        <img class="excel-icon" src="img/svg-icons/excel.svg" alt="excel icon">
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        </div>
</template>

<script>
import BudgetAttachFile from "./budget-attach-file";

export default {
    name: 'FormsSaveBtn',
    components: { BudgetAttachFile, },
    props: {
        progress: {
            type: Number,
            required: true,
            defaut: 0
        },
        isReportUploading: {
            type: Boolean,
            required: true,
            defaut: false
        },
        form: {
            type: Object,
            required: true,
            defaut: { 
                code: ''
                }
        },
        guListLen: {
            type: Number,
            defaut: 0
        },
        header: {
            type: Object,
            required: false,
        },

        isParentGuExist: {
            type: Boolean,
            required: false,
            defaut: false
        },

        isHeadGu: {
            type: Boolean,
            required: false,
            defaut: false
        },
        isGkkpPaid: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    methods: {
        onClick(fileType = 'xls', batch = false, oneGu = false, type = null) {
            this.$emit('checkSignatories', fileType, batch, oneGu);
            if (batch) {
                this.$emit('downloadBatchReports', fileType, oneGu, type);
            } else {
                this.$emit('downloadRep', fileType);
            }
        },

        onClickPaid(fileType = 'xls', batch = false, oneGu = false) {
            this.$emit('checkSignatories', fileType, batch, oneGu);
            if (batch) {
                this.$emit('downloadBatchReportsPaid', fileType, oneGu);
            } else {
                this.$emit('downloadRepPaid', fileType);
            }
        },

        adjustDropdownMenuStyle() {
            const dropdownMenu = this.$refs.dropdown.$el.querySelector('.dropdown-menu');
            const dropdownBtn = this.$refs.dropdown.$el.querySelector('button');
            const shift = dropdownMenu.offsetWidth - dropdownBtn.offsetWidth;
            if (dropdownMenu) {
                dropdownMenu.style.transform = `translate3d(-${shift}px, 30px, 0px)`;
            }

            const dropdownLinks = this.$refs.dropdown.$el.querySelectorAll('.dropdown-item');
            dropdownLinks.forEach(dropdownLink => {
                dropdownLink.style.display = 'flex';
                dropdownLink.style.justifyContent = 'space-between';
            })

            const pdfLinks = this.$refs.dropdown.$el.querySelectorAll('.pdf-link');
            pdfLinks.forEach(pdfLink => {
                pdfLink.style.width = '100%';
                pdfLink.style.padding = '1px';
                pdfLink.addEventListener("mouseenter", () => {
                    pdfLink.style.border = '2px dotted gray';
                    pdfLink.style.borderRadius = '2px';
                });

                pdfLink.addEventListener("mouseleave", () => {
                    pdfLink.style.border = 'none';
                });
            })
            
            const excelLinks = this.$refs.dropdown.$el.querySelectorAll('.excel-link');
            excelLinks.forEach(excelLink => {
                excelLink.style.paddingLeft = '2px';
                excelLink.style.borderLeft = '1px solid gray'    
            })

            const excelIcons = this.$refs.dropdown.$el.querySelectorAll('.excel-icon');
            excelIcons.forEach(excelIcon => {
                excelIcon.style.padding = '2px';
                excelIcon.addEventListener("mouseenter", () => {
                    excelIcon.style.padding = '1px';
                    excelIcon.style.border = '2px dotted gray';
                    excelIcon.style.borderRadius = '2px';
                });

                excelIcon.addEventListener("mouseleave", () => {
                    excelIcon.style.padding = '2px';
                    excelIcon.style.border = 'none';
                });
            })
        }, // смещение дропдауна скачивания отчетев для форм 02-159-1 и -2
    },

    computed: {
        formLocalText() {
            const localizedText = this.$t("modules.budget_request.calculation_forms.reports.report_headers.common_fields.line_3");
            return this.$i18n.locale === 'kk' ? `${this.form.code} ${localizedText}` : `${localizedText} ${this.form.code}`;
        },
        formLocalPaid() {
            const paid_services = this.$t("modules.budget_request.calculation_forms.table_headers.common.paid_services");
            return `${this.formLocalText} (${paid_services})`;
        },
        isGkkpMode() {
            return this.header && this.header.mode === 'gkkp';
        },
        buttonsText() {
            return this.$t("modules.budget.budget_request");
        },
        batchReportLocalText() {
            if (this.$store.state.isDownloadButtonEnabled) return this.buttonsText.uploadIsProgress;
            if (this.isGkkpMode) return this.buttonsText.batchUploadGkkpButton;
            return this.buttonsText.batchUploadGuButton;
        },
        batchReportGkkpPaid() {
            const paid_services = this.$t("modules.budget_request.calculation_forms.table_headers.common.paid_services");
            return `${this.buttonsText.batchUploadGkkpButton} (${paid_services})`;
        },
        batchReportAbpLocalText() {
            if (this.$store.state.isDownloadButtonEnabled) return this.buttonsText.uploadIsProgress;
            return this.buttonsText.batchUploadAbpButton;
        },
        batchReportEducationDepText() {
            if (this.$store.state.isDownloadButtonEnabled) return this.buttonsText.uploadIsProgress;
            return this.buttonsText.batchUploaEduButton;
        },
        isABPReportAvailable() {
            return this.guListLen > 1 && !this.isGkkpMode && this.isHeadGu;
        }
    }
}
</script>

<style scoped>
.spinner-border-sm {
    width: 1rem !important;
}

.download-rep_dropdown-item {
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.link-item-hover {
    color: rgb(203, 63, 63) !important;
}



</style>